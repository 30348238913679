import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CTABackgroundColor from "../components/CTA/CTABackgroundColor";
import InstagramFeed from "../components/Repeating/InstagramFeed";
import Accordion from "../components/Accordion/Accordion";

const Page = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Premium Pet Products San Diego | Bella Woof"
            description="Feed, bathe, and care for your pet with our selection of premium pet products at Bella Woof. Our store carries a wealth of products. Learn more here!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <div className="container">
            <header className="pt-4 md:pt-10 pb-2">
               <h1>Premium Pet Products</h1>
            </header>
         </div>

         <section className="pb-10 md:pb-28">
            <div className="hidden md:block">
               <Img fluid={data.heroDesktop.childImageSharp.fluid} alt="Premium Pet Products" />
            </div>
            <div className="md:hidden">
               <Img fluid={data.heroMobile.childImageSharp.fluid} alt="Premium Pet Products" />
            </div>
         </section>

         <div className="container">
            <section className="pb-20 md:pb-28">
               <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-16 lg:gap-x-24">
                  <div>
                     <div className="mb-0 md:mb-18">
                        <h2>Premium Pet Products for Dogs & Cats</h2>
                        <p className="md:text-xl mb-0 md:mb-8">
                           Feed, bathe, and care for your pet with our selection of premium pet products. Our regularly updated retail store carries a
                           variety of essential supplies for your dog or cat including:
                        </p>
                     </div>
                     <div className="hidden md:block">
                        <Img className="ml-auto" fluid={data.intro.childImageSharp.fluid} alt="About Our Services" />
                     </div>
                  </div>
                  <div>
                     <div className="relative border border-solid border-primary rounded-3xl px-4 md:px-8 py-4 md:py-8 mb-8 md:mb-10">
                        <p className="font-heading text-gray-900 font-bold text-lg mb-4">Food & Treats</p>
                        <ul>
                           <li>Natural chews</li>
                           <li>Frozen raw food, bones, & treats</li>
                           <li>Air-dried and freeze-dried raw food and treats</li>
                           <li>Frozen lightly cooked food</li>
                           <li>Dry food</li>
                           <li>Canned food</li>
                        </ul>
                        <div className="absolute right-0 bottom-0 w-full hidden md:block">
                           <Img className="ml-auto -mb-4 -mr-6" fluid={data.foodTreats.childImageSharp.fluid} alt="Food + Treats" />
                        </div>
                     </div>

                     <div className="relative border border-solid border-secondary rounded-3xl px-4 md:px-8 py-4 md:py-8 mb-8 md:mb-10">
                        <p className="font-heading text-gray-900 font-bold text-lg mb-4">Accessories & Toys</p>
                        <ul>
                           <li>Apparel</li>
                           <li>Enrichment toys</li>
                           <li>Bathing and grooming products</li>
                           <li>Harnesses, collars & leashes</li>
                        </ul>
                        <div className="absolute right-0 bottom-0 w-full hidden md:block">
                           <Img className="ml-auto -mb-4 -mr-6" fluid={data.accessories.childImageSharp.fluid} alt="Accessories, Toys + Tools" />
                        </div>
                     </div>

                     <div className="relative border border-solid border-tertiary rounded-3xl px-4 md:px-8 py-4 md:py-8 mb-10 md:mb-0">
                        <p className="font-heading text-gray-900 font-bold text-lg mb-4">Supplements & Other Specialty Items</p>
                        <ul>
                           <li>Frozen raw goat milk and yogurt</li>
                           <li>Frozen raw fermented food</li>
                           <li>Bee pollen</li>
                           <li>Bone broth</li>
                           <li>CBD tinctures, edibles and topicals</li>
                           <li>Natural supplements</li>
                        </ul>
                        <div className="absolute right-0 bottom-0 w-full hidden md:block">
                           <Img
                              className="ml-auto -mb-4 -mr-6"
                              fluid={data.supplements.childImageSharp.fluid}
                              alt="Supplements & Other Specialty Items"
                           />
                        </div>
                     </div>

                     <div className="md:hidden">
                        <Img fluid={data.intro.childImageSharp.fluid} alt="About Our Services" />
                     </div>
                  </div>
               </div>
            </section>

            <section className="mb-20 md:mb-32">
               <p className="uppercase font-bold text-lg text-gray-900 text-center mb-4 md:mb-16">Some of our Premium Brands</p>
               <div className="grid grid-cols-4 lg:grid-cols-6 max-w-5xl mx-auto items-center">
                  <div>
                     <Img className="mx-auto" fluid={data.StellaChewys.childImageSharp.fluid} alt="Stella + Chewys" />
                  </div>
                  {/* <div>
                     <Img className="mx-auto" fluid={data.HolisticHound.childImageSharp.fluid} alt="Holistic Hound" />
                  </div> */}
                  <div>
                     <Img className="mx-auto" fluid={data.PetReleaf.childImageSharp.fluid} alt="Pet Releaf" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.SmallBatch.childImageSharp.fluid} alt="Small Batch" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.VitalEssentials.childImageSharp.fluid} alt="Vital Essentials" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.Primal.childImageSharp.fluid} alt="Primal" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.MyPerfectPet.childImageSharp.fluid} alt="My Perfect Pet" />
                  </div>
                  {/* <div>
                     <Img className="mx-auto" fluid={data.WWM.childImageSharp.fluid} alt="WWM" />
                  </div> */}
                  <div>
                     <Img className="mx-auto" fluid={data.ZiwiPeak.childImageSharp.fluid} alt="Ziwi Peak" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.TheHonestKitchen.childImageSharp.fluid} alt="The Honest Kitchen" />
                  </div>
                  {/* <div>
                     <Img className="mx-auto" fluid={data.Acana.childImageSharp.fluid} alt="Acana" />
                  </div> */}
                  <div>
                     <Img className="mx-auto" fluid={data.SuperSnouts.childImageSharp.fluid} alt="Super Snouts" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.NaturalDogCo.childImageSharp.fluid} alt="Natural Dog Co" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.maxotaRaw.childImageSharp.fluid} alt="Maxota Raw" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.EarthRated.childImageSharp.fluid} alt="Earth Rated" />
                  </div>
                  {/* <div>
                     <Img className="mx-auto" fluid={data.Grizzly.childImageSharp.fluid} alt="Grizzly" />
                  </div> */}
                  <div>
                     <Img className="mx-auto" fluid={data.Bixbi.childImageSharp.fluid} alt="Bixbi" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.Plato.childImageSharp.fluid} alt="Plato" />
                  </div>
                  {/* <div>
                     <Img className="mx-auto" fluid={data.PetKind.childImageSharp.fluid} alt="PetKind" />
                  </div> */}
                  <div>
                     <Img className="mx-auto" fluid={data.AnimalEssentials.childImageSharp.fluid} alt="Animal Essentials" />
                  </div>
                  {/* <div>
                     <Img className="mx-auto" fluid={data.Zukes.childImageSharp.fluid} alt="Zukes" />
                  </div> */}
                  {/* <div>
                     <Img className="mx-auto" fluid={data.OutbackHound.childImageSharp.fluid} alt="Outback Hound" />
                  </div> */}
                  {/* <div>
                     <Img className="mx-auto" fluid={data.Fromm.childImageSharp.fluid} alt="Fromm" />
                  </div> */}
                  <div>
                     <Img className="mx-auto" fluid={data.Chuckit.childImageSharp.fluid} alt="Chuck it" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.KONG.childImageSharp.fluid} alt="KONG" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.Nootie.childImageSharp.fluid} alt="Nootie" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.Earthbath.childImageSharp.fluid} alt="Earthbath" />
                  </div>
                  {/* <div>
                     <Img className="mx-auto" fluid={data.Farmina.childImageSharp.fluid} alt="Farmina" />
                  </div> */}
                  <div>
                     <Img className="mx-auto" fluid={data.HimalayanPetSupply.childImageSharp.fluid} alt="Himalayan Pet Supply" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.EarthAnimal.childImageSharp.fluid} alt="Earth Animal" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.PuppyCake.childImageSharp.fluid} alt="Puppy Cake" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.Tuffys.childImageSharp.fluid} alt="Tuffys" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.Answers.childImageSharp.fluid} alt="Answers" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.Carna4.childImageSharp.fluid} alt="Carna4" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.Tropiclean.childImageSharp.fluid} alt="Tropiclean" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.PuddleJumperPups.childImageSharp.fluid} alt="Puddle Jumper Pups" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.Tripett.childImageSharp.fluid} alt="Tripett" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.IcelandicPlus.childImageSharp.fluid} alt="Icelandic+" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.Barkworthies.childImageSharp.fluid} alt="Barkworthies" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.K9Natural.childImageSharp.fluid} alt="K9 Natural" />
                  </div>
                  <div>
                     <Img className="mx-auto" fluid={data.FelineNatural.childImageSharp.fluid} alt="Feline Natural" />
                  </div>
                  
               </div>
            </section>

            <section className="mb-20 md:mb-32">
               <div className="grid md:grid-cols-2 gap-y-7 md:gap-x-10 lg:gap-x-20 items-center">
                  <div className="order-2 md:order-1">
                     <h2>About Our Re-Tail Products</h2>
                     <p>
                        Nourish your pet with healthy food and supplements, treat them to their new favorite toy, and keep them happy and clean with
                        natural bath products. Whatever your pet needs, we’ve got you covered at our La Jolla store*. We carry natural and holistic
                        products for your pet, guaranteed to make their tails wag with glee.
                     </p>
                     <p className="mb-0">
                        <i>*Online store coming soon</i>
                     </p>
                  </div>
                  <div className="order-1 md:order-2">
                     <div className="grid grid-rows-2 grid-cols-2 grid-flow-col gap-2 md:gap-4">
                        <div className="col-span-1 row-span-2">
                           <Img fluid={data.about1.childImageSharp.fluid} alt="About Our Services" className="rounded-3xl" />
                        </div>
                        <div className="col-span-1 row-span-1">
                           <Img fluid={data.about2.childImageSharp.fluid} alt="About Our Services" className="rounded-3xl" />
                        </div>
                        <div className="row-span-1 col-span-1">
                           <Img fluid={data.about3.childImageSharp.fluid} alt="About Our Services" className="rounded-3xl" />
                        </div>
                     </div>
                  </div>
               </div>
            </section>

            <section className="mb-20 md:mb-32">
               <div className="grid md:grid-cols-2 items-center gap-y-7">
                  <div>
                     <Img className="rounded-3xl md:ml-auto" fluid={data.giftCard.childImageSharp.fluid} alt="Gift Cards" />
                  </div>
                  <div className="md:pl-24">
                     <h2>Gift Cards</h2>
                     <p className="mb-0">
                        Give the gift of Bella Woof! Perfect for any occasion, our gift cards can be used at our La Jolla store for grooming &
                        training services and premium pet products.  
                     </p>
                  </div>
               </div>
            </section>

            <section className="bg-secondary_light border border-solid border-secondary rounded-lg px-6 pt-14 md:pt-16 pb-12 md:pb-20">
               <div className="max-w-3xl mx-auto">
                  <img className={`mb-6 mx-auto`} src={data.info.publicURL} alt="Info" />
                  <header className="text-center mb-8 md:mb-12">
                     <h2 className="text-lg md:text-5xl">Product Policies</h2>
                  </header>
                  <Accordion className="shadow-secondary" title="Merchandise Return & Exchange Policy">
                     <p>
                        2 wks with receipt, original tag & no wear or damage. We can not accept returns or exchanges on toys, leashes, collars,
                        clothes, beds, natural chews, etc. destroyed or damaged by your pet. Unfortunately, we can not guarantee merchandise, even the
                        toughest merchandise, from damage & destruction.
                     </p>
                  </Accordion>
                  <Accordion className="shadow-secondary" title="Food Return & Exchange Policy">
                     <p>
                        We can not accept returns or exchanges on frozen or refrigerated merchandise. We can not accept returns or exchanges on opened
                        bagged, bottled, canned or boxed food, snacks, supplements, etc.
                     </p>
                  </Accordion>
               </div>
            </section>
         </div>

         <CTABackgroundColor>
            <h2>Give your dog good things.</h2>
            <p>
               Your dogs deserve the finer things in life. We carry pet-approved food, treats, and pet supplies made by dog lovers like you! Call or
               send us a message to find out more about our retail products.
            </p>
         </CTABackgroundColor>

         <InstagramFeed />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Products_FB.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Products_Twitter.jpg" }) {
         publicURL
      }
      heroDesktop: file(relativePath: { eq: "products/1.0 primo-products-herov2.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 2880, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      heroMobile: file(relativePath: { eq: "products/1.0 primo-products-hero-mobile.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 750, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      intro: file(relativePath: { eq: "products/2.0 Intro Dog.png" }) {
         childImageSharp {
            fluid(maxWidth: 462, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      foodTreats: file(relativePath: { eq: "products/dog-food-2.png" }) {
         childImageSharp {
            fluid(maxWidth: 177, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      accessories: file(relativePath: { eq: "products/3.2 Accessories Toys + Tools.png" }) {
         childImageSharp {
            fluid(maxWidth: 161, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      supplements: file(relativePath: { eq: "products/3.3 Specialty Items.png" }) {
         childImageSharp {
            fluid(maxWidth: 188, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      about1: file(relativePath: { eq: "products/Left.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 532, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      about2: file(relativePath: { eq: "products/Right-Top.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 532, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      about3: file(relativePath: { eq: "products/Right-Bottom.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 532, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      giftCard: file(relativePath: { eq: "products/Gift Cards.png" }) {
         childImageSharp {
            fluid(maxWidth: 1096, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      Acana: file(relativePath: { eq: "products/product-logos/Acana.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      AnimalEssentials: file(relativePath: { eq: "products/product-logos/Animal Essentials.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Answers: file(relativePath: { eq: "products/product-logos/Answers.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Barkworthies: file(relativePath: { eq: "products/product-logos/Barkworthies.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Bixbi: file(relativePath: { eq: "products/product-logos/Bixbi.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Carna4: file(relativePath: { eq: "products/product-logos/Carna4.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Chuckit: file(relativePath: { eq: "products/product-logos/Chuck it.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      EarthAnimal: file(relativePath: { eq: "products/product-logos/Earth Animal.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      EarthRated: file(relativePath: { eq: "products/product-logos/Earth Rated.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Earthbath: file(relativePath: { eq: "products/product-logos/Earthbath.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Farmina: file(relativePath: { eq: "products/product-logos/Farmina.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      FelineNatural: file(relativePath: { eq: "products/product-logos/Feline Natural.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Fromm: file(relativePath: { eq: "products/product-logos/Fromm.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Grizzly: file(relativePath: { eq: "products/product-logos/Grizzly.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      HimalayanPetSupply: file(relativePath: { eq: "products/product-logos/Himalayan Pet Supply.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      HolisticHound: file(relativePath: { eq: "products/product-logos/Holistic Hound.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      IcelandicPlus: file(relativePath: { eq: "products/product-logos/Icelandic+.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      K9Natural: file(relativePath: { eq: "products/product-logos/K9 Natural.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      KONG: file(relativePath: { eq: "products/product-logos/KONG.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      maxotaRaw: file(relativePath: { eq: "products/product-logos/maxota-raw.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      MyPerfectPet: file(relativePath: { eq: "products/product-logos/My Perfect Pet.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      NaturalDogCo: file(relativePath: { eq: "products/product-logos/Natural Dog Co.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Nootie: file(relativePath: { eq: "products/product-logos/Nootie.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      OutbackHound: file(relativePath: { eq: "products/product-logos/Outback Hound.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      PetReleaf: file(relativePath: { eq: "products/product-logos/Pet Releaf.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      PetKind: file(relativePath: { eq: "products/product-logos/PetKind.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Plato: file(relativePath: { eq: "products/product-logos/Plato.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Primal: file(relativePath: { eq: "products/product-logos/Primal.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      PuddleJumperPups: file(relativePath: { eq: "products/product-logos/Puddle Jumper Pups.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      PuppyCake: file(relativePath: { eq: "products/product-logos/Puppy Cake.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      SmallBatch: file(relativePath: { eq: "products/product-logos/Small Batch.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      StellaChewys: file(relativePath: { eq: "products/product-logos/Stella + Chewy_s.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      SuperSnouts: file(relativePath: { eq: "products/product-logos/Super Snouts.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      TheHonestKitchen: file(relativePath: { eq: "products/product-logos/The Honest Kitchen.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Tripett: file(relativePath: { eq: "products/product-logos/Tripett.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Tropiclean: file(relativePath: { eq: "products/product-logos/Tropiclean.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Tuffys: file(relativePath: { eq: "products/product-logos/Tuffys.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      VitalEssentials: file(relativePath: { eq: "products/product-logos/Vital Essentials.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      WWM: file(relativePath: { eq: "products/product-logos/WWM.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      ZiwiPeak: file(relativePath: { eq: "products/product-logos/Ziwi Peak.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      Zukes: file(relativePath: { eq: "products/product-logos/Zukes.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 320, quality: 100) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      info: file(relativePath: { eq: "global/info.svg" }) {
         publicURL
      }
   }
`;

export default Page;
